import React from "react";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Login,Create, Admin, Agendamentos, Planos, BackupPass,} from '../Containers'
import PrivateRoute from "./private-route";
import paths from '../constants/paths'



function MyRoutes(){

    return(
        
        <Router>
            <Routes>
               
                <Route  path="/"  element={<Login/>} />
                <Route  path="/cadastro" element={<Create/>}  />
                <Route  path={paths.planos} element={<Planos/>}  />
                <Route  path={`/agenda/:id`} element={<Agendamentos/>}  />
        
                <Route path={paths.RecoverPass}  element={<BackupPass/>} />


                <Route  path="/filter" element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />
                <Route  path={paths.Pagamento} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />
                <Route  path={paths.Agenda} element={<PrivateRoute redirectTo="/">
                    <Admin  />
                </PrivateRoute>}  />
                <Route  path={paths.Collaborator} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />
                <Route  path={paths.Servicos} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  /> 
                <Route  path={paths.Horario} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />
                <Route  path={paths.Comandas} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />
                <Route  path={paths.financeiro} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.config} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.Despesas} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.AgendaOnline} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.Dashboard} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.Suporte} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

                <Route  path={paths.Comissoes} element={<PrivateRoute redirectTo="/">
                    <Admin/>
                </PrivateRoute>}  />

            </Routes>
        </Router>
    )
    
}

export default MyRoutes

