
const paths = {
    
    Agenda: '/agendaFilter',
    AgendaOnline: '/AgendaOnline',
    Collaborator: '/colaborador',
    Pagamento: '/Pagamento',
    Servicos: '/service',
    Horario: '/horarios',
    Comandas: '/comandas',
    financeiro: '/financeiro',
    config: '/config',
    login: '/',
    agendamento: '/agendamento/:id',
    Despesas: '/despesas',
    planos: '/planos',
    RecoverPass: '/backPass',
    Dashboard: '/dashboard',
    Suporte: '/suport',
    Comissoes: '/comissao',
}

export default paths 
